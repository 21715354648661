<template>
  <div class="address__wrapper">
    <div class="item_title">
      <div class="item_titleline"></div>收货地址管理
    </div>
    <a-button type="primary" icon="plus" style="margin-bottom: 12px" @click="getAddress()" v-perms="'add'">新增收货地址</a-button>
    <div class="body_content">
      <div v-for="item in list" :key="item.addressId" class="address_item_box">
        <div class="address_item">
          <div class="address_itemTop">
            <a-row type="flex" justify="end">
              <a-col :span="5">
                <a-button type="link" icon="edit" @click="getAddress(item)" v-perms="'update'">编辑</a-button>
              </a-col>
              <a-col :span="5">
                <a-popconfirm
                  placement="top"
                  ok-text="确认"
                  cancel-text="取消"
                  @confirm="deletes(item)"
                  v-perms="'delete'"
                >
                  <template slot="title">确认删除当前数据吗？</template>
                  <a-button type="link" icon="delete">删除</a-button>
                </a-popconfirm>
              </a-col>
            </a-row>
          </div>
          <div class="address_itemBotton">
            <a-row>
              <a-col :span="6">
                <div class="address_itemName">收货人：</div>
              </a-col>
              <a-col :span="18">
                <div class="address_itemText">{{ item.consignee }}</div>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="6">
                <div class="address_itemName">联系电话：</div>
              </a-col>
              <a-col :span="18">
                <div class="address_itemText">{{ item.phone }}</div>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="6">
                <div class="address_itemName">收货地址：</div>
              </a-col>
              <a-col :span="18">
                <div
                  class="address_itemText"
                >{{ item.province }}{{ item.city }}{{ item.county }}{{ item.address }}</div>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
    </div>
    <addressForm ref="addressForm" @success="init" />
  </div>
</template>

<script>
import {
  addAddress,
  Addresslist,
  DeleteAddress,
  ModflyAddress,
  queryAllDistrict,
  queryAllProvince
} from '@/api/materialShop'
import addressForm from './form.vue'

export default {
  name: 'Index',
  components: {
    addressForm
  },
  data () {
    // 手机格式验证
    const validatorPhone = (rule, value, callback) => {
      // 如果为空值，就抛出错误
      if (!value) {
        callback(new Error('请输入手机号'))
      } else {
        // 正则判断手机号格式的格式，正则判断失败抛出错误，否则直接callback()
        if (!/^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(value)) {
          callback(new Error('手机号格式不正确'))
        } else {
          callback()
        }
      }
    }
    return {
      other: '',
      list: []
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    getAddress (item) {
      if (item) {
        this.$refs.addressForm.editModel(item)
      } else {
        this.$refs.addressForm.createModel(item)
      }
    },
    deletes (item) {
       const params = {
        id: item.addressId
      }
      DeleteAddress(params)
        .then(res => {
          if (res.success) {
            this.$message.success('操作成功')
            this.visibleDelete = false
            this.init()
          }
          console.log(res)
        })
        .catch(err => {
          console.log(err)
        })
    },
    init () {
      const params = {
        pageIndex: 1,
        pageSize: 1000
      }
      Addresslist(params)
        .then(res => {
          this.list = res.data
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="less">
@import url('../../../common/style');
@import url('./index');
.address__wrapper {
  height: 100%;
  .body_content {
    height: calc(100% - 80px);
    padding: 24px;
    padding-top: 0px;
    overflow: hidden auto;
    .address_item_box {
      width: 33.3%;
      display: inline-block;
    }
    .address_item {
      display: inline-block;
    }
  }
}
</style>
